import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { fetchFilterParams } from ".";
import { getCurrentPage, ObjectToQueryParam } from "../utils";
import { getSelectedSiteId } from "./shared";

export type CollectionPoint = {
  Code: string;
  CollectionPoint: string;
  CradlePoint: string;
  Id: string;
  SiteId: string;
  Type: string;
};

const initialState = {
  loading: false,
  data: [] as CollectionPoint[],
  recordsTotal: 0,
  currentPage: 1,
};

export const fetchCollectionPoints = createAsyncThunk("collectionPoints/fetch", async (params: fetchFilterParams, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  if (!siteId) return Promise.reject();
  const currentPage = getCurrentPage({ offset: params.offset, pageSize: params.limit });

  return axios.get(`/collectionPoints?SiteId=${siteId}&${ObjectToQueryParam(params)}`).then((res) => ({ currentPage, ...res.data }));
});

const collectionPointsSlice = createSlice({
  name: "collectionPoints",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCollectionPoints.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.recordsTotal = action.payload.recordsTotal;
      state.currentPage = action.payload.currentPage;
    },
    [fetchCollectionPoints.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchCollectionPoints.rejected.type]: (state, action) => {
      state.loading = false;
    },
  },
});

export default collectionPointsSlice.reducer;
