import React from "react";
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

function useCognito(isConfigLoaded) {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [user, setUser] = React.useState<object | undefined>();
  const [internalUser, setInternalUser] = React.useState<boolean>();

  React.useEffect(() => {
    if (!isConfigLoaded) return;

    Auth.currentAuthenticatedUser()
      .then((authData: CognitoUser) => {
        setLoggedIn(true);
        setUser(authData);
        const userRoles = authData.getSignInUserSession().getAccessToken().payload["cognito:groups"];
        if (userRoles && userRoles.find((r) => r.includes("Okta"))) {
          setInternalUser(true);
        } else {
          setInternalUser(false);
        }
      })
      .catch((e) => {
        console.log("error", e);
        Auth.federatedSignIn();
      });
    /* return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
      console.log("auth", authData);
    }); */
  }, [isConfigLoaded]);

  return { loggedIn: loggedIn && user, internalUser: internalUser };
}

export default useCognito;
