import React, { Suspense } from "react";
import "./App.less";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import useCognito from "./hooks/useCognito";
import useInterceptors from "./hooks/useInterceptor";
import useConfigLoader from "./hooks/useConfigLoader";

const Dashboard = React.lazy(() => import(/* webpackChunkName: "Dashboard" */ "./pages/Dashboard"));
const Fleet = React.lazy(() => import(/* webpackChunkName: "Fleet" */ "./pages/Fleet"));
const Forecast = React.lazy(() => import(/* webpackChunkName: "Forecast" */ "./pages/Forecast"));
const ChargingActivity = React.lazy(() => import(/* webpackChunkName: "ChargingActivity" */ "./pages/ChargingActivity"));
const EquipmentHealth = React.lazy(() => import(/* webpackChunkName: "EquipmentHealth" */ "./pages/EquipmentHealth"));
const Maintenance = React.lazy(() => import(/* webpackChunkName: "Maintenance" */ "./pages/Maintenance"));
const Profile = React.lazy(() => import(/* webpackChunkName: "Contract" */ "./pages/Profile"));

function App() {
  const { isConfigLoaded, envConfig } = useConfigLoader();
  const isReady = useInterceptors(envConfig);
  const { loggedIn, internalUser } = useCognito(isConfigLoaded);

  if (!loggedIn || !isReady) return <Loading />;

  return (
    <Router>
      <Header internalUser={internalUser || true} />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route path="/fleet">
            <Fleet />
          </Route>
          <Route path="/forecast">
            <Forecast />
          </Route>
          <Route path="/charging-activity/:name?/:daysToShowParam?/:selectedTypeParam?">
            <ChargingActivity />
          </Route>
          <Route path="/equipment-health">
            <EquipmentHealth internalUser={internalUser} />
          </Route>
          <Route path="/maintenance">
            <Maintenance internalUser={internalUser} />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
        </Switch>
      </Suspense>
      <Footer />
    </Router>
  );
}

export default App;
