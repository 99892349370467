export function ForkLift({ style }) {
  return (
    <svg style={style} enableBackground="new 0 0 24 24" height="30" viewBox="0 0 24 24" width="30" xmlns="http://www.w3.org/2000/svg">
      <path d="m13.441 14h-5.941c-1.379 0-2.5-1.122-2.5-2.5v-7c0-.276.224-.5.5-.5h3.5c1.077 0 2.03.687 2.372 1.709l2.544 7.632c.051.152.025.32-.069.451s-.244.208-.406.208zm-7.441-9v6.5c0 .827.673 1.5 1.5 1.5h5.248l-2.325-6.974c-.204-.614-.777-1.026-1.423-1.026z" />
      <path d="m15.5 19h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h1.5v-4h-7.5c-1.379 0-2.5-1.122-2.5-2.5v-.5h-2.5c-.827 0-1.5.673-1.5 1.5v5c0 .276.225.5.5.5h1c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.827 0-1.5-.673-1.5-1.5v-5c0-1.378 1.121-2.5 2.5-2.5h3c.276 0 .5.224.5.5v1c0 .827.673 1.5 1.5 1.5h8c.276 0 .5.224.5.5v5c0 .276-.224.5-.5.5z" />
      <path d="m9.5 19h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3c.276 0 .5.224.5.5s-.224.5-.5.5z" />
      <path d="m23.5 19h-8c-.276 0-.5-.224-.5-.5v-15c0-.276.224-.5.5-.5s.5.224.5.5v14.5h7.5c.276 0 .5.224.5.5s-.224.5-.5.5z" />
      <path d="m4.5 21c-1.379 0-2.5-1.122-2.5-2.5s1.121-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.121 2.5-2.5 2.5zm0-4c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5z" />
      <path d="m11.5 21c-1.379 0-2.5-1.122-2.5-2.5s1.121-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.121 2.5-2.5 2.5zm0-4c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5z" />
    </svg>
  );
}

export function Health({ style }) {
  return (
    <svg style={style} id="Capa_1" enableBackground="new 0 0 512 512" height="30" viewBox="0 0 512 512" width="30" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m512 233.067h-30.456c8.218-18.026 12.129-35.31 12.129-52.676 0-76.681-62.385-139.066-139.066-139.066-37.566 0-72.626 14.751-98.607 41-25.981-26.248-61.042-41-98.607-41-76.681 0-139.066 62.385-139.066 139.066 0 17.366 3.911 34.65 12.129 52.676h-30.456v30h47.614c9.108 13.353 20.445 27.29 34.155 42.097 78.215 84.47 174.232 165.51 174.232 165.51s96.041-81.069 174.23-165.51c13.711-14.807 25.047-28.745 34.155-42.097h47.614zm-408.218 51.715c-6.971-7.529-13.231-14.749-18.823-21.715h76.498l18.912-36.516 77.102 127.626 79.619-164.849 33.215 73.739h56.737c-5.592 6.966-11.852 14.186-18.823 21.715-62.479 67.475-127.971 125.545-152.219 146.536-24.246-20.988-89.724-79.043-152.218-146.536zm285.912-51.715-51.657-114.681-83.508 172.904-76.171-126.086-35.147 67.864h-79.06c-10.762-18.81-15.824-35.954-15.824-52.676 0-60.139 48.927-109.066 109.066-109.066 34.246 0 65.843 15.64 86.691 42.911l11.916 15.588 11.917-15.589c20.847-27.27 52.444-42.911 86.69-42.911 60.14 0 109.066 48.927 109.066 109.066 0 16.721-5.062 33.865-15.824 52.676z" />
      </g>
    </svg>
  );
}

export function BatteryIcon({ size = 30 }) {
  return (
    <svg style={{ marginRight: 3 }} x="0px" y="0px" width={size} height={size} viewBox="0 0 420.75 420.75">
      <g>
        <polygon points="238.059,127.334 151.049,239.062 206.206,239.062 182.691,322.104 269.701,210.375 214.544,210.375 	" />
        <path
          d="M258.188,0h-95.625c-10.566,0-19.125,8.559-19.125,19.125V38.25h-38.25C89.371,38.25,76.5,51.121,76.5,66.938v325.125
		c0,15.816,12.871,28.688,28.688,28.688h210.375c15.816,0,28.688-12.871,28.688-28.688V66.938c0-15.816-12.871-28.688-28.688-28.688
		h-38.25V19.125C277.312,8.559,268.754,0,258.188,0z M315.562,57.375c5.278,0,9.562,4.284,9.562,9.562v325.125
		c0,5.278-4.284,9.562-9.562,9.562H105.188c-5.278,0-9.562-4.284-9.562-9.562V66.938c0-5.278,4.284-9.562,9.562-9.562h57.375h95.625
		H315.562z"
        />
      </g>
    </svg>
  );
}

export function ChargerIcon({ size = 30 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24" width={size} fill="#000000">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M16 9v4.66l-3.5 3.51V19h-1v-1.83L8 13.65V9h8m0-6h-2v4h-4V3H8v4h-.01C6.9 6.99 6 7.89 6 8.98v5.52L9.5 18v3h5v-3l3.5-3.51V9c0-1.1-.9-2-2-2V3z" />
    </svg>
  );
}
