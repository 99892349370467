import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { groupBy } from "lodash";
import { fetchFilterParams, RootSlice } from ".";
import { getCurrentPage, ObjectToQueryParam } from "../utils";
import { getSelectedSiteId } from "./shared";

export type Battery = {
  ApplicationType: string;
  AssetNumber: string;
  BDR: string;
  Battery: string;
  BatteryType: string;
  Brand: string;
  Building: string;
  ConnectTarget: string;
  ContractBuildingId: string;
  ContractCode: string;
  ContractEnd: string;
  ContractID: string;
  ContractName: string;
  ContractStart: string;
  Height: string;
  Id: string;
  LastTransmission: string;
  Length: string;
  MfgDate: string;
  Model: string;
  NominalCapacity: string;
  NumOfCells: string;
  ReportingAsset: string;
  SiteId: string;
  TruckCode: string;
  TruckType: string;
  Usage90Days: string;
  Voltage: string;
  Weight: string;
  Width: string;
};

export type CycleHoursDaily = {
  BatteryId: string;
  EventDate: string;
  Metric: string;
  SiteId: string;
  Value: number;
};

export type CycleHoursHourly = {
  BatteryId: string;
  Device: string;
  EventDate: string;
  EventDuration: number;
  EventEnd: string;
  EventStart: string;
  Metric: string;
  SiteId: string;
};
export type ConnectTimeDaily = {
  BatteryId: string;
  EventDate: string;
  Metric: string;
  SiteId: string;
  Target: number;
  Value: number;
};

export type ConnectTimeWeekday = {
  BatteryId: string;
  Sort: number;
  Metric: string;
  Target: number;
  Value: number;
  WeekDay: string;
};

export type CycleHoursTotal = {
  BatteryId: string;
  MaxDate: string;
  Metric: string;
  MinDate: string;
  Value: number;
  TruckType: string;
};

export type BatteryMaintenanceAlerts = {
  Alert: string;
  AlertDate: string;
  Battery: string;
  BatteryId: string;
  Details: string;
  Id: string;
  SiteId: string;
};

export type BatteryOperatorScore = {
  siteId: string;
  batteryId: string;
  WorkDays: number;
  DaysAboveTarget: number;
  Score: string;
};
export type LightBatteries = {
  Id: string;
  Battery: string;
  TruckType: string;
};

export type BatteryHourlyTotal = {
  "AmpHrs Discharged": number;
  "AmpHrs Returned": number;
  "Charge Ratio": string;
  "Charge Time": number;
  "Discharge Time": number;
  "Idle Time": number;
};

type Forecast = {
  BatteryId: string;
  EventDate: string;
  Metric: string;
  Value: number;
  Title: string;
};

const initialState = {
  loading: false,
  lightBatteries: [] as LightBatteries[],
  loadingLightBatteries: false,
  data: [] as Battery[],
  recordsTotal: 0,
  currentPage: 1,
  current: {} as Battery,
  batteryHourly: {},
  loadingBatteryHourly: false,
  batteryHourlyTotal: {} as BatteryHourlyTotal,
  loadingBatteryHourlyTotal: false,
  cycleHoursHourly: [] as CycleHoursHourly[],
  loadingCycleHoursHourly: false,
  cycleHoursDaily: [] as CycleHoursDaily[],
  loadingCycleHoursDaily: false,
  connectTimeDaily: [] as ConnectTimeDaily[],
  loadingConnectTimeDaily: false,
  connectTimeWeekday: [] as ConnectTimeWeekday[],
  loadingConnectTimeWeekday: false,
  selectedBatteryId: sessionStorage.getItem("selectedBatteryId"),
  selectedBatteryIds: (!!sessionStorage.getItem("selectedBatteryIds") ? JSON.parse(sessionStorage.getItem("selectedBatteryIds")) : []) as string[],
  cycleHoursTotal: [] as CycleHoursTotal[],
  loadingCycleHoursTotal: false,
  maintenanceAlerts: [] as BatteryMaintenanceAlerts[],
  loadingMaintenanceAlerts: false,
  operatorScore: {} as BatteryOperatorScore,
  loadingOperatorScore: false,
  loadingForecast: false,
  forecast: [] as Forecast[],
  loadingBatteryForecastSummary: false,
  batteryForecastSummary: {},
  daysToShow: 30,
};

export const fetchBatteries = createAsyncThunk("batteries/fetch", async (params: fetchFilterParams, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  if (!siteId) return Promise.reject();
  const currentPage = getCurrentPage({ offset: params.offset, pageSize: params.limit });

  return axios.get(`/batteries?SiteId=${siteId}&${ObjectToQueryParam(params)}`).then((res) => ({ currentPage, ...res.data }));
});

export const fetchBatteryById = createAsyncThunk("batteries/fetch", async (id: string, { getState }) => {
  return axios.get(`/batteries/${id}`).then((res) => res.data);
});

export const fetchBatteryHourly = createAsyncThunk("batteryHourly/fetch", async (id: string, { getState }) => {
  if (!id) return Promise.reject();
  return axios.get(`/batteries/${id}/metrics-hourly`).then((res) => res.data);
});

type BatteryHourlyTotalParams = {
  id: string;
  startDate: string;
  endDate: string;
};
export const fetchBatteryHourlyTotal = createAsyncThunk("batteryHourlyTotal/fetch", async (params: BatteryHourlyTotalParams, { getState }) => {
  if (!params.id) return Promise.reject();
  return axios.get(`/batteries/${params.id}/metrics-hourly-total?${ObjectToQueryParam(params)}`).then((res) => res.data);
});

export const fetchCycleHoursDaily = createAsyncThunk("cycleHoursDaily/fetch", async (id: string, { getState }) => {
  return axios.get(`/batteries/cycle-hours-daily?batteryId=${id}`).then((res) => res.data);
});

export const fetchConnectTimeDaily = createAsyncThunk("connectTimeDaily/fetch", async (id: string, { getState }) => {
  return axios.get(`/batteries/connect-time-daily?batteryId=${id}`).then((res) => res.data);
});

export const fetchCycleHoursHourly = createAsyncThunk("cycleHoursHourly/fetch", async (batteryIds: string[], { getState }) => {
  if (!batteryIds.length) return Promise.reject();
  const state = getState() as RootSlice;

  const body = { batteryIds, days: state.batteries.daysToShow };
  return axios.post(`/batteries/cycle-hours-hourly`, body).then((res) => res.data);
});

export const fetchCycleHoursTotal = createAsyncThunk("cycleHoursTotal/fetch", async (batteryIds: string[], { getState }) => {
  if (!batteryIds.length) return Promise.reject();
  const state = getState() as RootSlice;

  const body = { batteryIds, days: state.batteries.daysToShow };
  return axios.post(`/batteries/cycle-hours-total`, body).then((res) => res.data);
});

export const fetchConnectTimeWeekday = createAsyncThunk("connectTimeWeekday/fetch", async (batteryIds: string[], { getState }) => {
  if (!batteryIds.length) return Promise.reject();
  const state = getState() as RootSlice;

  const body = { batteryIds, days: state.batteries.daysToShow };
  return axios.post(`/batteries/connect-time-weekday-avg`, body).then((res) => res.data);
});

export const fetchBatteryOperatorScore = createAsyncThunk("batteryOperatorScore/fetch", async (batteryIds: string[], { getState }) => {
  if (!batteryIds.length) return Promise.reject();
  const state = getState() as RootSlice;

  const body = { batteryIds, days: state.batteries.daysToShow };
  return axios.post(`/batteries/operator-score`, body).then((res) => res.data);
});

type MaintenanceAlertsParams = {
  searchText: string;
  date: string;
};

export const fetchBatteryMaintenanceAlerts = createAsyncThunk(
  "batteryMaintenanceAlerts/fetch",
  async (params: MaintenanceAlertsParams, { getState }) => {
    const siteId = getSelectedSiteId(getState);
    if (!siteId) return Promise.reject();

    return axios.get(`batteries/maintenance-alerts?siteId=${siteId}&${ObjectToQueryParam(params)}`).then((res) => res.data);
  }
);

export const fetchAllBatteries = createAsyncThunk("allBatteries/fetch", async (_, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  if (!siteId) return Promise.reject();

  return axios.get(`batteries/light?siteId=${siteId}`).then((res) => res.data);
});

export const fetchBatteryForecast = createAsyncThunk("BatteryForecast/fetch", async (batteryIds: string[], { getState }) => {
  if (!batteryIds.length) return Promise.reject();
  const body = { batteryIds };
  return axios.post(`batteries/forecast`, body).then((res) => res.data);
});

export const fetchBatteryForecastSummary = createAsyncThunk("BatteryForecastSummary/fetch", async (batteryIds: string[], { getState }) => {
  if (!batteryIds.length) return Promise.reject();
  const body = { batteryIds };
  return axios.post(`batteries/forecast-summary`, body).then((res) => res.data);
});

// const normalizeDateSeries = (data, uniqueDates: Set<string>) => {
//   //this func will add entry for missing dateTime to resolve sync issue on charts
//   const _uniqueDates = new Set(uniqueDates);
//   const _data = [...data];

//   _data.forEach((element) => {
//     _uniqueDates.delete(element.EventDateTime);
//   });
//   _uniqueDates.forEach((date) => {
//     _data.push({ EventDateTime: date, Value: null });
//   });

//   return orderBy(_data, "EventDateTime");
// };

const batteriesSlice = createSlice({
  name: "batteries",
  initialState,
  reducers: {
    setSelectedBatteries(state, action) {
      state.selectedBatteryIds = action.payload;
      sessionStorage.setItem("selectedBatteryIds", JSON.stringify(action.payload));
    },
    setSelectedBattery(state, action) {
      state.selectedBatteryId = action.payload;
      sessionStorage.setItem("selectedBatteryId", action.payload);
    },
    setDaysToShow(state, action) {
      state.daysToShow = action.payload;
    },
  },
  extraReducers: {
    [fetchBatteries.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.recordsTotal = action.payload.recordsTotal;
      state.currentPage = action.payload.currentPage;
    },
    [fetchBatteries.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchBatteries.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [fetchBatteryHourly.fulfilled.type]: (state, action) => {
      const DateSet: Set<String> = new Set(action.payload.map((e) => e["EventDateTime"]));
      const dates = Array.from(DateSet).sort();
      //getting first date and last date
      const firstDate = dates[0];
      const lastDate = dates[dates.length - 1];
      const grouped = groupBy(action.payload, "Metric");
      const groupedKeys = Object.keys(grouped);
      const normalized = {};
      //here adding start end end date to make chart length same for every
      groupedKeys.forEach((key) => {
        const temp = [...grouped[key]];
        if (grouped[key][0].EventDateTime !== firstDate) {
          temp.unshift({ Metric: key, EventDateTime: firstDate, Value: null });
        }
        if (grouped[key][grouped[key].length - 1].EventDateTime !== lastDate) {
          temp.push({ Metric: key, EventDateTime: lastDate, Value: null });
        }
        normalized[key] = temp;
      });

      state.batteryHourly = normalized;
      state.loadingBatteryHourly = false;
    },
    [fetchBatteryHourly.pending.type]: (state, action) => {
      state.loadingBatteryHourly = true;
    },
    [fetchBatteryHourly.rejected.type]: (state, action) => {
      state.loadingBatteryHourly = false;
    },

    [fetchBatteryHourlyTotal.fulfilled.type]: (state, action) => {
      state.batteryHourlyTotal = action.payload;
      state.loadingBatteryHourlyTotal = false;
    },
    [fetchBatteryHourlyTotal.pending.type]: (state, action) => {
      state.loadingBatteryHourlyTotal = true;
    },
    [fetchBatteryHourlyTotal.rejected.type]: (state, action) => {
      state.loadingBatteryHourlyTotal = false;
    },

    [fetchCycleHoursHourly.fulfilled.type]: (state, action) => {
      state.loadingCycleHoursHourly = false;
      state.cycleHoursHourly = action.payload;
    },
    [fetchCycleHoursHourly.pending.type]: (state, action) => {
      state.loadingCycleHoursHourly = true;
    },
    [fetchCycleHoursHourly.rejected.type]: (state, action) => {
      state.loadingCycleHoursHourly = false;
      state.cycleHoursHourly = [];
    },
    [fetchCycleHoursDaily.fulfilled.type]: (state, action) => {
      state.loadingCycleHoursDaily = false;
      state.cycleHoursDaily = action.payload;
    },
    [fetchCycleHoursDaily.pending.type]: (state, action) => {
      state.loadingCycleHoursDaily = true;
    },
    [fetchCycleHoursDaily.rejected.type]: (state, action) => {
      state.loadingCycleHoursDaily = false;
      state.cycleHoursDaily = [];
    },
    [fetchConnectTimeDaily.fulfilled.type]: (state, action) => {
      state.loadingConnectTimeDaily = false;
      state.connectTimeDaily = action.payload;
    },
    [fetchConnectTimeDaily.pending.type]: (state, action) => {
      state.loadingConnectTimeDaily = true;
    },
    [fetchConnectTimeDaily.rejected.type]: (state, action) => {
      state.loadingConnectTimeDaily = false;
    },
    [fetchConnectTimeWeekday.fulfilled.type]: (state, action) => {
      state.loadingConnectTimeWeekday = false;
      state.connectTimeWeekday = action.payload;
    },
    [fetchConnectTimeWeekday.pending.type]: (state, action) => {
      state.loadingConnectTimeWeekday = true;
    },
    [fetchConnectTimeWeekday.rejected.type]: (state, action) => {
      state.loadingConnectTimeWeekday = false;
      state.connectTimeWeekday = [];
    },
    [fetchCycleHoursTotal.fulfilled.type]: (state, action) => {
      state.loadingCycleHoursTotal = false;
      state.cycleHoursTotal = action.payload;
    },
    [fetchCycleHoursTotal.pending.type]: (state, action) => {
      state.loadingCycleHoursTotal = true;
    },
    [fetchCycleHoursTotal.rejected.type]: (state, action) => {
      state.loadingCycleHoursTotal = false;
      state.cycleHoursTotal = [];
    },
    [fetchBatteryMaintenanceAlerts.fulfilled.type]: (state, action) => {
      state.loadingMaintenanceAlerts = false;
      state.maintenanceAlerts = action.payload;
    },
    [fetchBatteryMaintenanceAlerts.pending.type]: (state, action) => {
      state.loadingMaintenanceAlerts = true;
    },
    [fetchBatteryMaintenanceAlerts.rejected.type]: (state, action) => {
      state.loadingMaintenanceAlerts = false;
    },
    [fetchBatteryOperatorScore.fulfilled.type]: (state, action) => {
      state.loadingOperatorScore = false;
      state.operatorScore = action.payload;
    },
    [fetchBatteryOperatorScore.pending.type]: (state, action) => {
      state.loadingOperatorScore = true;
    },
    [fetchBatteryOperatorScore.rejected.type]: (state, action) => {
      state.loadingOperatorScore = false;
      state.operatorScore = {} as BatteryOperatorScore;
    },
    [fetchAllBatteries.fulfilled.type]: (state, action) => {
      state.loadingLightBatteries = false;
      state.lightBatteries = action.payload;

      //if not selected battery, set first one
      const firstBattery = action.payload?.[0];

      if (!firstBattery) return;

      if (!state.selectedBatteryId) state.selectedBatteryId = firstBattery.Id;

      if (!state.selectedBatteryIds.length) state.selectedBatteryIds = [firstBattery.Id];
    },
    [fetchAllBatteries.pending.type]: (state, action) => {
      state.loadingLightBatteries = true;
    },
    [fetchAllBatteries.rejected.type]: (state, action) => {
      state.loadingLightBatteries = false;
    },
    [fetchBatteryForecast.fulfilled.type]: (state, action) => {
      state.loadingForecast = false;
      state.forecast = action.payload;
    },
    [fetchBatteryForecast.pending.type]: (state, action) => {
      state.loadingForecast = true;
    },
    [fetchBatteryForecast.rejected.type]: (state, action) => {
      state.loadingForecast = false;
      state.forecast = [];
    },

    [fetchBatteryForecastSummary.fulfilled.type]: (state, action) => {
      state.loadingBatteryForecastSummary = false;
      state.batteryForecastSummary = action.payload;
    },
    [fetchBatteryForecastSummary.pending.type]: (state, action) => {
      state.loadingBatteryForecastSummary = true;
    },
    [fetchBatteryForecastSummary.rejected.type]: (state, action) => {
      state.loadingBatteryForecastSummary = false;
      state.batteryForecastSummary = {};
    },
  },
});

export const { setSelectedBattery, setSelectedBatteries, setDaysToShow } = batteriesSlice.actions;
export type BatterySlice = typeof initialState;
export default batteriesSlice.reducer;
