import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { CycleHoursDaily } from "./batteriesSlice";
import { getSelectedSiteId } from "./shared";

export type Site = {
  Id: string;
  CustomerId: string;
  Customer: string;
  Site: string;
  RegionCode: string;
  Region: string;
  City: string;
  State: string;
  TravelTime: number;
  Active: string;
};

export type MetricsDaily = {
  EventDate: string;
  Metric: string;
  SiteId: string;
  Value: number;
};

export type AssetsCount = {
  BatteryCount: number;
  BuildingCount: number;
  ChargerCount: number;
  CollectionPointCount: number;
  ContractCount: number;
  DepartmentCount: number;
  SiteId: number;
  TruckCount: number;
};

export type ContractScore = {
  Actual: number;
  Score: string;
  SiteId: string;
  Target: number;
};

export type OperatorScore = {
  DaysAboveTarget: number;
  Score: string;
  WorkDays: number;
  siteId: string;
};

export type MaintenanceAlertCounts = {
  Batteries: number;
  Chargers: number;
  SiteId: string;
};

export type ChargingScore = {
  DaysAboveTarget: number;
  Score: string;
  WorkDays: number;
  siteId: string;
};

const initialState = {
  loading: false,
  loadingAssetsCount: false,
  loadingMetricsDaily: false,
  loadingCycleHoursDaily: false,
  cycleHoursDaily: [] as CycleHoursDaily[],
  assetsCount: {} as AssetsCount,
  data: [] as Site[],
  metricsDaily: [] as MetricsDaily[],
  selectedSiteId: localStorage.getItem("selectedSiteId"),
  selectedSiteName: localStorage.getItem("selectedSiteName"),
  contractScore: {} as ContractScore,
  operatorScore: {} as OperatorScore,
  chargingScore: {} as ChargingScore,
  loadingChargingScore: false,
  loadingOperatorScore: false,
  loadingContractScore: false,
  maintenanceAlertsCount: {} as MaintenanceAlertCounts,
  loadingMaintenanceAlertsCount: false,
};

export type SitesSlice = typeof initialState;

export const fetchSites = createAsyncThunk("sites/fetch", async (_) => {
  return axios.get(`/sites`).then((res) => res.data);
});
export const fetchSitesIfEmpty = createAsyncThunk("sites/fetch", async (_, { getState }) => {
  const state = getState() as { sites: SitesSlice };
  if (state.sites.data?.length) {
    return Promise.reject();
  }
  return axios.get(`/sites`).then((res) => res.data);
});

export const fetchAssetsCount = createAsyncThunk("assetsCount/fetch", async (_, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  if (!siteId) return Promise.reject();

  return axios.get(`/sites/${siteId}/asset-count`).then((res) => res.data);
});

export const fetchMetricsDaily = createAsyncThunk("metricsDaily/fetch", async (_, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  if (!siteId) return Promise.reject();

  return axios.get(`/sites/${siteId}/metrics-daily`).then((res) => res.data);
});

export const fetchSiteCycleHoursDaily = createAsyncThunk("cycleHoursDaily/fetch", async (_, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  if (!siteId) return Promise.reject();

  return axios.get(`/sites/${siteId}/cycle-hours-daily`).then((res) => res.data);
});

export const fetchSiteContractScore = createAsyncThunk("contractScore/fetch", async (_, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  if (!siteId) return Promise.reject();

  return axios.get(`/sites/${siteId}/contract-score`).then((res) => res.data);
});

export const fetchSiteOperatorScore = createAsyncThunk("operatorScore/fetch", async (_, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  if (!siteId) return Promise.reject();

  return axios.get(`/sites/${siteId}/operator-score`).then((res) => res.data);
});

export const fetchSiteChargingScore = createAsyncThunk("siteChargingScore/fetch", async (_, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  if (!siteId) return Promise.reject();

  return axios.get(`/sites/${siteId}/charging-score`).then((res) => res.data);
});
export const fetchMaintenanceAlertCounts = createAsyncThunk("maintenanceAlertCounts/fetch", async (_, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  if (!siteId) return Promise.reject();

  return axios.get(`/sites/${siteId}/maintenance-alerts-count`).then((res) => res.data);
});

const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    setSelectedSite(state, action) {
      state.selectedSiteId = action.payload.id;
      state.selectedSiteName = action.payload.name;
      localStorage.setItem("selectedSiteId", action.payload.id);
      localStorage.setItem("selectedSiteName", action.payload.name);
    },
  },
  extraReducers: {
    [fetchSites.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [fetchSites.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchSites.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [fetchAssetsCount.fulfilled.type]: (state, action) => {
      state.loadingAssetsCount = false;
      state.assetsCount = action.payload;
    },
    [fetchAssetsCount.pending.type]: (state, action) => {
      state.loadingAssetsCount = true;
    },
    [fetchAssetsCount.rejected.type]: (state, action) => {
      state.loadingAssetsCount = false;
      state.assetsCount = {} as AssetsCount;
    },
    [fetchMetricsDaily.fulfilled.type]: (state, action) => {
      state.loadingMetricsDaily = false;
      state.metricsDaily = action.payload;
    },
    [fetchMetricsDaily.pending.type]: (state, action) => {
      state.loadingMetricsDaily = true;
    },
    [fetchMetricsDaily.rejected.type]: (state, action) => {
      state.loadingMetricsDaily = false;
    },
    [fetchSiteCycleHoursDaily.fulfilled.type]: (state, action) => {
      state.loadingCycleHoursDaily = false;
      state.cycleHoursDaily = action.payload;
    },
    [fetchSiteCycleHoursDaily.pending.type]: (state, action) => {
      state.loadingCycleHoursDaily = true;
    },
    [fetchSiteCycleHoursDaily.rejected.type]: (state, action) => {
      state.loadingCycleHoursDaily = false;
    },
    [fetchSiteContractScore.fulfilled.type]: (state, action) => {
      state.loadingContractScore = false;
      state.contractScore = action.payload;
    },
    [fetchSiteContractScore.pending.type]: (state, action) => {
      state.loadingContractScore = true;
    },
    [fetchSiteContractScore.rejected.type]: (state, action) => {
      state.loadingContractScore = false;
      state.contractScore = {} as ContractScore;
    },

    [fetchSiteOperatorScore.fulfilled.type]: (state, action) => {
      state.loadingOperatorScore = false;
      state.operatorScore = action.payload;
    },
    [fetchSiteOperatorScore.pending.type]: (state, action) => {
      state.loadingOperatorScore = true;
    },
    [fetchSiteOperatorScore.rejected.type]: (state, action) => {
      state.loadingOperatorScore = false;
      state.operatorScore = {} as OperatorScore;
    },

    [fetchSiteChargingScore.fulfilled.type]: (state, action) => {
      state.loadingChargingScore = false;
      state.chargingScore = action.payload;
    },
    [fetchSiteChargingScore.pending.type]: (state, action) => {
      state.loadingChargingScore = true;
    },
    [fetchSiteChargingScore.rejected.type]: (state, action) => {
      state.loadingChargingScore = false;
      state.chargingScore = {} as ChargingScore;
    },

    [fetchMaintenanceAlertCounts.fulfilled.type]: (state, action) => {
      state.loadingMaintenanceAlertsCount = false;
      state.maintenanceAlertsCount = action.payload;
    },
    [fetchMaintenanceAlertCounts.pending.type]: (state, action) => {
      state.loadingMaintenanceAlertsCount = true;
    },
    [fetchMaintenanceAlertCounts.rejected.type]: (state, action) => {
      state.loadingMaintenanceAlertsCount = false;
      state.maintenanceAlertsCount = {} as MaintenanceAlertCounts;
    },
  },
});
export const { setSelectedSite } = sitesSlice.actions;
export default sitesSlice.reducer;
