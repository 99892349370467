import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { getSelectedSiteId } from "./shared";

export type Contract = {
  Building: string;
  ContractBuildingId: string;
  ContractCode: string;
  ContractDepartmentId: string;
  ContractName: string;
  Department: string;
  DepartmentConnectTIme: string;
  DepartmentRunTimeHours: string;
  EQTime: string;
  EndDate: string;
  Id: string;
  SiteId: string;
  StartDate: string;
  TruckClass: string;
  TruckType: string;
};

export type ProfileCount = {
  BatteryCount: number;
  BuildingCount: number;
  ChargerCount: number;
  CollectionPointCount: number;
  ContractCount: number;
  DepartmentCount: number;
  SiteId: number;
  TruckCount: number;
};

const initialState = {
  loading: false,
  data: [] as Contract[],
  loadingProfileCount: false,
  profileCount: {} as ProfileCount,
};

export const fetchContracts = createAsyncThunk("contracts/fetch", async (_, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  if (!siteId) return Promise.reject();

  return axios.get(`/contracts?SiteId=${siteId}`).then((res) => res.data);
});

export const fetchProfileCount = createAsyncThunk("profileCount/fetch", async (_, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  return axios.get(`/sites/${siteId}/profile-count`).then((res) => res.data);
})

const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchContracts.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [fetchContracts.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchContracts.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [fetchProfileCount.fulfilled.type]: (state, action) => {
      state.loadingProfileCount = false;
      state.profileCount = action.payload;
    },
    [fetchProfileCount.pending.type]: (state, action) => {
      state.loadingProfileCount = true;
    },
    [fetchProfileCount.rejected.type]: (state, action) => {
      state.loadingProfileCount = false;
      state.profileCount = {} as ProfileCount;
    },
  },
});

export type ContractsSlice = typeof initialState;
export default contractsSlice.reducer;
