import axios from "axios";
import { Auth } from "aws-amplify";
import { useAppDispatch } from "../store";
import { useEffect, useState } from "react";
import { notification } from "antd";

function useInterceptors(envConfig) {
  const dispatch = useAppDispatch();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!envConfig) return;
    setReady(true);

    const requestInterceptor = axios.interceptors.request.use(
      async function (config) {
        const currentUser = await Auth.currentAuthenticatedUser();
        const token = currentUser.getSignInUserSession().getIdToken().jwtToken;
        config.baseURL = envConfig.API_URL;
        config.headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      // Add a response interceptor
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        const status = error.response.status;
        if (!status || status === 404) return Promise.reject(error);

        notification.error({
          message: "Error " + error?.response?.status,
          description: error.message || "something went wrong",
        });

        if (status === 401) {
          //localStorage.clear();
          Auth.federatedSignIn();
          console.log("logout should called");
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.request.eject(responseInterceptor);
    };
  }, [dispatch, envConfig]);

  return ready;
}

export default useInterceptors;
