import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchCustomersIfEmpty, setSelectedCustomer } from "../store/customersSlice";

function useCostumers() {
  const dispatch = useAppDispatch();
  const { data: customers, loading: loadingCustomers, selectedCustomerId, selectedCustomerName } = useAppSelector((state) => state.customers);
  useEffect(() => {
    dispatch(fetchCustomersIfEmpty());
  }, [dispatch]);
  const setSelected = useCallback(({ id, name }) => dispatch(setSelectedCustomer({ id, name })), [dispatch]);
  return { customers, loadingCustomers, selectedCustomerId, selectedCustomerName, setSelectedCustomer: setSelected, setSelected };
}

export default useCostumers;
