import { Spin } from "antd";
import React from "react";
import styled from "styled-components";

const CenteredDiv = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
`;

const Loading = ({ relative = false, height = undefined }) => (
  <CenteredDiv style={{ position: !relative ? "absolute" : "relative", height }}>
    <Spin size="large" />
  </CenteredDiv>
);

export default Loading;
