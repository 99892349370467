import React from "react";
import styles from "./Footer.module.scss";
import { useHistory } from "react-router-dom";

function Footer() {
  const history = useHistory();
  return (
    <footer className={styles.footer}>
      <img onClick={() => history.push("/")} style={{ cursor: "pointer" }} width="120" src="/concentric-white.svg" alt="Contentric Logo" />
      <label className={styles.footerLabel}>&#169; {new Date().getFullYear()} CONCENTRIC</label>
    </footer>
  );
}

export default Footer;
