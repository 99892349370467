import { startCase } from "lodash";
import { trimCount } from "../shared";

export function ObjectToQueryParam(params: Object): string {
  return (
    Object.keys(params)
      //@ts-ignore
      .filter((key) => params[key])
      //@ts-ignore
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join("&")
  );
}

export function formatDate(dateString: string) {
  return new Date(dateString?.slice(0, 19)).toLocaleString();
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return [s4(), s4(), "-", s4(), "-", s4(), "-", s4(), "-", s4(), s4(), s4()].join("").toLowerCase();
}

export function getCurrentPage({ offset, pageSize }: { offset: number; pageSize: number }) {
  const fraction = Math.floor(offset / pageSize);
  return fraction > 0 ? fraction + 1 : 1;
}

export function columnsToStartCase(columns) {
  return columns?.map((e) => ({ ...e, title: e.title?.toUpperCase() !== e.title ? startCase(e.title) : e.title }));
}
export function getColumnNames(data) {
  if (!data || !data[0]) return [];
  return Object.keys(data[0])?.filter((e) => !(e.toLowerCase().startsWith("id") || e.toLowerCase().endsWith("id")));
}

export const convertToDuration = (date1, date2) => {
  let distance = Math.abs(date1 - date2);
  const hours = Math.floor(distance / 3600000);
  distance -= hours * 3600000;
  const minutes = Math.floor(distance / 60000);
  distance -= minutes * 60000;
  const seconds = Math.floor(distance / 1000);
  let res = "";
  //`0${num}`.slice(-1) returns last 2 chars
  if (hours) {
    res += `${hours}h `;
  }
  if (minutes) {
    res += `${minutes}m `;
  }
  if (seconds) {
    res += `${seconds}s `;
  }
  return res;
};

export const toUTCDateTimeStamp = (str: string) => {
  let dateString = "";
  if (str.charAt(10) === "T") {
    dateString = str + "Z";
  } else {
    dateString = str + "T00:00:00Z";
  }

  return new Date(dateString).getTime();
};

export const timestampToDate = (number) => {
  return new Date(number).toISOString().slice(0, 19);
};

export function filter(inputValue, path): boolean {
  return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
}

export function ObjectToItems(obj: Object) {
  return Object.keys(obj || {})
    .filter((e) => !e.toLowerCase().startsWith("id") && !e.toLowerCase().endsWith("id"))
    .map((name) => ({ name: trimCount(name), value: obj[name] as string }));
}

export function minAndMax(arr: number[]) {
  let min = Infinity;
  let max = -Infinity;
  for (let current of arr) {
    min = Math.min(min, current);
    max = Math.max(max, current);
  }
  return [min, max];
}
