import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootSlice } from ".";

export type Customer = {
  Active: string;
  Customer: string;
  Id: string;
  Origin: string;
};

const initialState = {
  loading: false,
  data: [] as Customer[],
  selectedCustomerId: localStorage.getItem("selectedCustomerId"),
  selectedCustomerName: localStorage.getItem("selectedCustomerName"),
};

export const fetchCustomersIfEmpty = createAsyncThunk("customers/fetch", async (_, { getState }) => {
  const state = getState() as RootSlice;
  if (state.sites.data?.length) {
    return Promise.reject();
  }
  return axios.get(`/customers`).then((res) => res.data);
});

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setSelectedCustomer(state, action) {
      state.selectedCustomerId = action.payload.id;
      state.selectedCustomerName = action.payload.name;
      localStorage.setItem("selectedCustomerId", action.payload.id);
      localStorage.setItem("selectedCustomerName", action.payload.name);
    },
  },
  extraReducers: {
    [fetchCustomersIfEmpty.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [fetchCustomersIfEmpty.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchCustomersIfEmpty.rejected.type]: (state, action) => {
      state.loading = false;
    },
  },
});

export type CustomersSlice = typeof initialState;
export const { setSelectedCustomer } = customersSlice.actions;
export default customersSlice.reducer;
