import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { fetchFilterParams, RootSlice } from ".";
import { getCurrentPage, ObjectToQueryParam } from "../utils";
import { getSelectedSiteId } from "./shared";

export type Charger = {
  AmpHourSetting: string;
  ApplicationType: string;
  AssetNumber: string;
  BrandName: string;
  Building: string;
  CanBusCode: string;
  CanBusName: string;
  Charger: string;
  ChargerName: string;
  ContractBuildingId: string;
  ContractCode: string;
  ContractDepartmentId: string;
  ContractID: string;
  ContractName: string;
  CoolectionPointCode: string;
  CoolectionPointName: string;
  Department: string;
  EndDate: string;
  EqualizationShift: string;
  Id: string;
  InputVoltage: string;
  LastTransmission: string;
  MaxDCAmps: string;
  Model: string;
  OutputVoltage: string;
  PowerRatingkwHrs: string;
  ReportingAsset: string;
  RunTimeHours: string;
  SiteId: string;
  StartDate: string;
  TruckClass: string;
  TruckCode: string;
  TruckType: string;
};

export type ChargerMaintananceAlert = {
  Alert: string;
  AlertDate: string;
  Charger: string;
  ChargerId: string;
  Details: string;
  SiteId: string;
};

export type ChargerScore = {
  siteId: string;
  chargerId: string;
  WorkDays: number;
  DaysAboveTarget: number;
  Score: string;
};

export type LightChargers = {
  Id: string;
  Charger: string;
  TruckType: string;
};

type TurnsTotal = {
  Metric: string;
  Target: number;
  TruckType: String;
  Value: number;
};

type TurnsWeekday = {
  Metric: string;
  Sort: number;
  Target: number;
  WeekDay: String;
  Value: number;
};

type ChargerCycleHoursHourly = {
  ChargerId: string;
  Device: string;
  EventDate: string;
  EventDuration: number;
  EventEnd: string;
  EventStart: string;
  Metric: string;
  SiteId: string;
};

const initialState = {
  loading: false,
  data: [] as Charger[],
  loadingMaintenanceAlerts: false,
  maintenanceAlerts: [] as ChargerMaintananceAlert[],
  recordsTotal: 0,
  currentPage: 1,
  lightChargers: [] as LightChargers[],
  loadingLightChargers: false,
  selectedChargerIds: (!!sessionStorage.getItem("selectedChargerIds") ? JSON.parse(sessionStorage.getItem("selectedChargerIds")) : []) as string[],
  loadingChargingScore: false,
  chargingScore: {} as ChargerScore,
  loadingTurnsTotal: false,
  turnsTotal: [] as TurnsTotal[],
  loadingTurnsWeekday: false,
  turnsWeekday: [] as TurnsWeekday[],
  loadingChargerCycleHoursHourly: false,
  chargerCycleHoursHourly: [] as ChargerCycleHoursHourly[],
  daysToShow: 30,
};

export const fetchChargers = createAsyncThunk("chargers/fetch", async (params: fetchFilterParams, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  if (!siteId) return Promise.reject();

  const currentPage = getCurrentPage({ offset: params.offset, pageSize: params.limit });

  return axios.get(`/chargers?SiteId=${siteId}&${ObjectToQueryParam(params)}`).then((res) => ({ currentPage, ...res.data }));
});
type ChargerMaintenanceAlertParams = {
  searchText: string;
  date: string;
};

export const fetchChargersMaintenanceAlerts = createAsyncThunk(
  "chargersMaintenanceAlerts/fetch",
  async (params: ChargerMaintenanceAlertParams, { getState }) => {
    const siteId = getSelectedSiteId(getState);
    if (!siteId) return Promise.reject();

    return axios.get(`chargers/maintenance-alerts?siteId=${siteId}&${ObjectToQueryParam(params)}`).then((res) => res.data);
  }
);

export const fetchLightChargers = createAsyncThunk("lightChargers/fetch", async (_, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  if (!siteId) return Promise.reject();

  return axios.get(`chargers/light?siteId=${siteId}`).then((res) => res.data);
});

export const fetchChargerCycleHoursHourly = createAsyncThunk("chargerCycleHoursHourly/fetch", async (chargerIds: string[], { getState }) => {
  if (!chargerIds.length) return Promise.reject();
  const state = getState() as RootSlice;

  const body = { chargerIds, days: state.chargers.daysToShow };
  return axios.post(`/chargers/cycle-hours-hourly`, body).then((res) => res.data);
});

export const fetchChargingScore = createAsyncThunk("chargingScore/fetch", async (chargerIds: string[], { getState }) => {
  if (!chargerIds.length) return Promise.reject();
  const state = getState() as RootSlice;

  const body = { chargerIds, days: state.chargers.daysToShow };
  return axios.post(`/chargers/charging-score`, body).then((res) => res.data);
});

export const fetchTurnsWeekday = createAsyncThunk("TurnsWeekday/fetch", async (chargerIds: string[], { getState }) => {
  if (!chargerIds.length) return Promise.reject();
  const state = getState() as RootSlice;

  const body = { chargerIds, days: state.chargers.daysToShow };
  return axios.post(`/chargers/ah-turns-weekday-avg`, body).then((res) => res.data);
});

export const fetchTurnsTotal = createAsyncThunk("turnsTotal/fetch", async (chargerIds: string[], { getState }) => {
  if (!chargerIds.length) return Promise.reject();
  const state = getState() as RootSlice;

  const body = { chargerIds, days: state.chargers.daysToShow };
  return axios.post(`/chargers/ah-turns-avg`, body).then((res) => res.data);
});

const chargersSlice = createSlice({
  name: "chargers",
  initialState,
  reducers: {
    setSelectedChargers(state, action) {
      state.selectedChargerIds = action.payload;
      sessionStorage.setItem("selectedChargerIds", JSON.stringify(action.payload));
    },
    setDaysToShow(state, action) {
      state.daysToShow = action.payload;
    },
  },
  extraReducers: {
    [fetchChargers.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.recordsTotal = action.payload.recordsTotal;
      state.currentPage = action.payload.currentPage;
    },
    [fetchChargers.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchChargers.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [fetchChargersMaintenanceAlerts.fulfilled.type]: (state, action) => {
      state.loadingMaintenanceAlerts = false;
      state.maintenanceAlerts = action.payload;
    },
    [fetchChargersMaintenanceAlerts.pending.type]: (state, action) => {
      state.loadingMaintenanceAlerts = true;
    },
    [fetchChargersMaintenanceAlerts.rejected.type]: (state, action) => {
      state.loadingMaintenanceAlerts = false;
    },
    [fetchLightChargers.fulfilled.type]: (state, action) => {
      state.loadingLightChargers = false;
      state.lightChargers = action.payload;

      //if not selected battery, set first one
      const firstCharger = action.payload?.[0];

      if (!firstCharger) return;

      if (!state.selectedChargerIds.length) state.selectedChargerIds = [firstCharger.Id];
    },
    [fetchLightChargers.pending.type]: (state, action) => {
      state.loadingLightChargers = true;
    },
    [fetchLightChargers.rejected.type]: (state, action) => {
      state.loadingLightChargers = false;
    },
    [fetchTurnsTotal.fulfilled.type]: (state, action) => {
      state.loadingTurnsTotal = false;
      state.turnsTotal = action.payload;
    },
    [fetchTurnsTotal.pending.type]: (state, action) => {
      state.loadingTurnsTotal = true;
    },
    [fetchTurnsTotal.rejected.type]: (state, action) => {
      state.loadingTurnsTotal = false;
      state.turnsTotal = [];
    },
    [fetchTurnsWeekday.fulfilled.type]: (state, action) => {
      state.loadingTurnsWeekday = false;
      state.turnsWeekday = action.payload;
    },
    [fetchTurnsWeekday.pending.type]: (state, action) => {
      state.loadingTurnsWeekday = true;
      state.turnsWeekday = [];
    },
    [fetchTurnsWeekday.rejected.type]: (state, action) => {
      state.loadingTurnsWeekday = false;
    },
    [fetchChargingScore.fulfilled.type]: (state, action) => {
      state.loadingChargingScore = false;
      state.chargingScore = action.payload;
    },
    [fetchChargingScore.pending.type]: (state, action) => {
      state.loadingChargingScore = true;
    },
    [fetchChargingScore.rejected.type]: (state, action) => {
      state.loadingChargingScore = false;
      state.chargingScore = {} as ChargerScore;
    },
    [fetchChargerCycleHoursHourly.fulfilled.type]: (state, action) => {
      state.loadingChargerCycleHoursHourly = false;
      state.chargerCycleHoursHourly = action.payload;
    },
    [fetchChargerCycleHoursHourly.pending.type]: (state, action) => {
      state.loadingChargerCycleHoursHourly = true;
    },
    [fetchChargerCycleHoursHourly.rejected.type]: (state, action) => {
      state.loadingChargerCycleHoursHourly = false;
      state.chargerCycleHoursHourly = [];
    },
  },
});

export const { setSelectedChargers, setDaysToShow } = chargersSlice.actions;
export type ChargersSlice = typeof initialState;
export default chargersSlice.reducer;
