import { useEffect, useState } from "react";
import styled from "styled-components";
import styles from "./Header.module.scss";
import { DashboardOutlined, RiseOutlined, PoweroffOutlined, DownOutlined, LoadingOutlined, ToolOutlined, FileTextOutlined } from "@ant-design/icons";
import { filter } from "../../utils";
import { colors } from "../../theme";
import { useHistory, useLocation } from "react-router-dom";
import { Cascader, Dropdown, Menu } from "antd";
import Auth from "@aws-amplify/auth";
import { Row, SharedPageProps } from "../../shared";
import useSites from "../../hooks/useSites";
import useCustomers from "../../hooks/useCustomers";
import { groupBy, last } from "lodash";
import { useDispatch } from "react-redux";
import { setSelectedBatteries, setSelectedBattery } from "../../store/batteriesSlice";
import { setSelectedChargers } from "../../store/chargersSlice";
import { ForkLift, Health } from "../../Icons";

const HeaderContainer = styled.div`
  box-shadow: var(--box-shadow);
  display: flex;
  background-color: black;
  height: 4rem;
  justify-content: space-between;
`;

const HeaderItem = styled.div`
  background-color: white;
  flex: 1;

  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  fill: #262626;
  &:hover {
    background-color: var(--primary);
    color: white;
    fill: white;
  }
  ${({ active }: { active?: boolean }) => active && `background-color: var(--primary); color: white; fill: white;`};
`;
const ItemText = styled.span`
  font-size: max(1vw, 10px);
  @media (max-width: 650px) {
    display: none;
  }
`;
const DropdownText = styled.div`
  text-align: center;
  vertical-align: middle;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  font-size: 1rem;
  display: flex;
  height: 100%;
`;
const iconStyle = { marginLeft: 20, marginRight: 20, fontSize: 20 };

function DropdownMenu() {
  return (
    <Menu selectedKeys={[]} style={{ backgroundColor: colors.primary, color: "white" }}>
      <Menu.Item className="hover-gray" onClick={() => Auth.signOut()}>
        Logout
      </Menu.Item>
    </Menu>
  );
}

function Header({ internalUser }: SharedPageProps) {
  const { loadingSites, sites, selectedSiteName, setSelectedSite } = useSites();
  const { loadingCustomers, customers, selectedCustomerName, setSelectedCustomer } = useCustomers();
  const [customersWithSites, setCustomersWithSites] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sites.length || !customers.length) return;
    const sitesGroupedByCustomerId = groupBy(
      sites.map((s) => ({ ...s, value: s.Id, label: s.Site })),
      "CustomerId"
    );
    setCustomersWithSites(customers?.map((c) => ({ ...c, value: c.Id, label: c.Customer, children: sitesGroupedByCustomerId[c.Id] || [] })));
  }, [customers, sites]);

  return (
    <div className={styles.header}>
      <HeaderContainer>
        <img
          onClick={() => history.push("/")}
          style={{ marginLeft: 20, cursor: "pointer" }}
          width="170"
          src="/concentric-white.svg"
          alt="Contentric Logo"
        />
        <img src="/raams-RGB-White.png" alt="" />

        <HeaderItem style={{ maxWidth: 500, marginRight: 10, backgroundColor: colors.primary, color: "white", padding: 20 }}>
          <Row style={{ marginRight: 5 }}>{loadingSites || loadingCustomers ? <LoadingOutlined spin color="white" /> : "Site"}</Row>

          <Cascader
            allowClear={false}
            defaultValue={[selectedCustomerName, selectedSiteName]}
            onChange={(selectedValues: string[], selectedOptions) => {
              const [selectedCustomer, selectedSite] = selectedOptions;
              if (!selectedSite || !selectedCustomer) return;

              setSelectedCustomer({ id: selectedCustomer.value, name: selectedCustomer.label });
              setSelectedSite({ id: selectedSite.value, name: selectedSite.label });

              dispatch(setSelectedBattery(""));
              dispatch(setSelectedBatteries([]));
              dispatch(setSelectedChargers([]));
            }}
            displayRender={(e) => last(e)}
            showSearch={{ filter }}
            style={{ width: "100%" }}
            options={customersWithSites}
            placeholder="Please select"
          />
          <Dropdown overlay={<DropdownMenu />} placement="bottomLeft" trigger={["click"]}>
            <DropdownText>
              <DownOutlined style={{ fontSize: 20 }} />
            </DropdownText>
          </Dropdown>
        </HeaderItem>
      </HeaderContainer>

      <HeaderContainer>
        <HeaderItem onClick={() => history.push("/")} active={location.pathname === "/"}>
          <DashboardOutlined style={iconStyle} />
          <ItemText>Dashboard</ItemText>
        </HeaderItem>
        <HeaderItem onClick={() => history.push("/fleet")} active={location.pathname === "/fleet"}>
          <ForkLift style={iconStyle} />
          <ItemText>Fleet</ItemText>
        </HeaderItem>
        <HeaderItem onClick={() => history.push("/charging-activity")} active={location.pathname.includes("/charging-activity")}>
          <PoweroffOutlined style={iconStyle} />
          <ItemText>Charging Activity</ItemText>
        </HeaderItem>
        {internalUser && (
          <HeaderItem onClick={() => history.push("/equipment-health")} active={location.pathname === "/equipment-health"}>
            <Health style={iconStyle} />
            <ItemText>Equipment Health</ItemText>
          </HeaderItem>
        )}
        {internalUser && (
          <HeaderItem onClick={() => history.push("/maintenance")} active={location.pathname === "/maintenance"}>
            <ToolOutlined style={iconStyle} />
            <ItemText>Maintenance</ItemText>
          </HeaderItem>
        )}

        <HeaderItem onClick={() => history.push("/forecast")} active={location.pathname === "/forecast"}>
          <RiseOutlined style={iconStyle} />
          <ItemText>Forecast</ItemText>
        </HeaderItem>
        <HeaderItem onClick={() => history.push("/profile")} active={location.pathname.includes("/profile")}>
          <FileTextOutlined style={iconStyle} />
          <ItemText>Profile</ItemText>
        </HeaderItem>
      </HeaderContainer>
    </div>
  );
}

export default Header;
