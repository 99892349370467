import React from "react";
import styled from "styled-components";
import { breakpoint, colors } from "./theme";

export const TextBold = styled.span`
  font-weight: bold;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Flex = styled.div`
  display: flex;
`;

type RowProps = {
  justify?: "start" | "center" | "space-between" | "space-around" | "space-evenly";
  alignItems?: "stretch" | "center" | "flex-start" | "flex-end" | "baseline" | "initial" | "inherit";
  breakpoint?: string;
};

export const Row = styled.div`
  display: flex;
  align-items: ${(props: RowProps) => props.alignItems || "center"};
  justify-content: ${(props: RowProps) => props?.justify};
  flex: 1;
  flex-direction: row;
  @media ${(props: RowProps) => props.breakpoint || breakpoint.device.maxSm} {
    flex-direction: column;
  }
`;

export const Circle = styled.div`
  width: 15px;
  height: 15px;
  margin: 5px;
  border-radius: 100%;
  background-color: ${(props: { color: string }) => props.color};
`;

const fontSizeMap = {
  xs: 14,
  sm: 16,
  md: 20,
  lg: 35,
  xl: 45,
};
type ColoredTextProps = {
  size?: keyof typeof fontSizeMap;
  color?: string;
  children: React.ReactChild;
  style?: React.CSSProperties;
  className?: string;
};

export const ColoredText = ({ style, size = "md", color = colors.primary, children, className }: ColoredTextProps) => {
  return (
    <span style={{ ...style, color, fontSize: fontSizeMap[size], fontWeight: "bold" }} className={className}>
      {children}
    </span>
  );
};

const CardWrapper = styled.div`
  margin: 1rem;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  transition: 0.3s;
`;

type ColoredCardProps = {
  title: string;
  children: JSX.Element | JSX.Element[];
  headerColor?: string;
  headerTextColor?: string;
};

export const ColoredCard = ({ headerColor = colors.primary, headerTextColor = "white", title, children }: ColoredCardProps) => {
  return (
    <CardWrapper>
      <div style={{ backgroundColor: headerColor, padding: "1rem", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
        <ColoredText color={headerTextColor}>{title}</ColoredText>
      </div>
      <div style={{ padding: "1rem" }}>{children}</div>
    </CardWrapper>
  );
};

const FilterWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  @media ${breakpoint.device.sm} {
    width: 30%;
    margin-bottom: 0px;
    align-items: center;
    flex-direction: row;
  }
`;

type FilterContainerProps = {
  title: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

export const FilterContainer = ({ style, title, children }: FilterContainerProps) => {
  return (
    <FilterWrapper style={style}>
      <span style={{ marginRight: "5px" }}>{title}</span> {children}
    </FilterWrapper>
  );
};

export type SharedPageProps = {
  internalUser: boolean;
};

export const trimCount = (str: string) => str?.replace("Count", "");
