import React, { DependencyList, useContext, useEffect, useRef } from "react";

const MountedContext = React.createContext({});

const ArrayIsEqual = (arr1: DependencyList, arr2: DependencyList) => {
  for (let i = 0; i < arr1.length; i++) {
    const temp1 = arr1[i];
    const temp2 = arr2[i];
    if (temp1 !== temp2) return false;
  }
  return true;
};

const FetchEffectProvider = ({ children }) => {
  const mountedComponents = useRef({});
  return <MountedContext.Provider value={mountedComponents.current}>{children}</MountedContext.Provider>;
};

export const useFetchEffect = (key: string, callback: () => any, deps: DependencyList) => {
  const mountedComponents = useContext(MountedContext);

  if (!mountedComponents) {
    console.error("You need to wrap component with FetchEffectProvider");
  }

  useEffect(() => {
    if (mountedComponents[key] && !ArrayIsEqual(mountedComponents[key], deps)) {
      mountedComponents[key] = deps;
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    if (!mountedComponents[key]) {
      mountedComponents[key] = deps;
      callback();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default FetchEffectProvider;
