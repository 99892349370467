const size = {
  xs: "320px",
  sm: "768px",
  md: "950px",
  lg: "1200px",
  xlg: "1400px",
};
const device = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  lg: `(min-width: ${size.lg})`,
  xlg: `(min-width: ${size.xlg})`,
  maxXs: `(max-width: ${size.xs})`,
  maxSm: `(max-width: ${size.sm})`,
  maxMd: `(max-width: ${size.md})`,
  maxLg: `(max-width: ${size.lg})`,
  maxXLg: `(max-width: ${size.xlg})`,
};

export const breakpoint = {
  size,
  device,
};

export const colors = {
  primary: "#0099d6",
  yellow: "#F7B500",
  green: "#22C349",
  red: "#DF201F",
  grey: "#6F6F6F",
  lightGrey: "#B5B5B5",
  darkBlue: "#075b84",
};
