import { useState, useEffect } from "react";
import axios from "axios";
import Amplify from "aws-amplify";

function useConfigLoader() {
  const [config, setConfig] = useState(null);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);

  useEffect(() => {
    if (isConfigLoaded) return;

    axios
      .get(window.location.origin + "/env.json")
      .then(function (response) {
        setConfig(response.data);
        setIsConfigLoaded(true);
        Amplify.configure(response.data.awsConfig);
      })
      .catch(function (error) {
        console.log("Could not load application configuration.");
        console.log(error);
      });
  }, [isConfigLoaded]);

  return { isConfigLoaded: isConfigLoaded, envConfig: config };
}

export default useConfigLoader;
