import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchAllBatteries } from "../store/batteriesSlice";
import { fetchLightChargers } from "../store/chargersSlice";
import { fetchSitesIfEmpty, setSelectedSite } from "../store/sitesSlice";

function useSites() {
  const dispatch = useAppDispatch();
  const sites = useAppSelector((state) => state.sites.data);
  const { loading: loadingSites, selectedSiteId, selectedSiteName } = useAppSelector((state) => state.sites);

  useEffect(() => {
    dispatch(fetchSitesIfEmpty());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAllBatteries());
    dispatch(fetchLightChargers());
  }, [selectedSiteId, dispatch]);

  const setSelected = useCallback(({ id, name }) => dispatch(setSelectedSite({ id, name })), [dispatch]);
  return {
    selectedSiteName,
    sites,
    loadingSites,
    setSelectedSite: setSelected,
    selectedSiteId,
  };
}

export default useSites;
