import { combineReducers, Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import sites, { SitesSlice } from "./sitesSlice";
import chargers, { ChargersSlice } from "./chargersSlice";
import batteries, { BatterySlice } from "./batteriesSlice";
import trucks from "./trucksSlice";
import contracts from "./contractsSlice";

import customers from "./customersSlice";
import collectionPoints from "./collectionPointsSlice";
const reducer = combineReducers({ sites, chargers, batteries, trucks, collectionPoints, contracts, customers });
export type RootState = ReturnType<typeof reducer>;
export type RootSlice = { sites: SitesSlice; batteries: BatterySlice; chargers: ChargersSlice };
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
const store = configureStore({
  reducer,
  //devTools: process.env.NODE_ENV === "development",
});

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export type fetchFilterParams = {
  searchText?: string;
  sortFields?: string;
  limit?: number;
  offset?: number;
};
