import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { fetchFilterParams } from ".";
import { getCurrentPage, ObjectToQueryParam } from "../utils";
import { getSelectedSiteId } from "./shared";
export type Truck = {
  Battery: string;
  Building: string;
  ContractBuildingId: string;
  ContractCode: string;
  ContractDepartmentId: string;
  ContractID: string;
  ContractName: string;
  Department: string;
  EndDate: string;
  Id: string;
  LastTransmission: string;
  RunTimeHours: string;
  SiteId: string;
  StartDate: string;
  Truck: string;
  TruckClass: string;
  TruckCode: string;
  TruckType: string;
};

type AllTruck = {
  Id: string;
  Truck: string;
  TruckCode: string;
};

const initialState = {
  loading: false,
  data: [] as Truck[],
  selectedTruckId: "",
  recordsTotal: 0,
  currentPage: 1,
  loadingAllTrucks: false,
  allTrucks: [] as AllTruck[],
};

export const fetchTrucks = createAsyncThunk("trucks/fetch", async (params: fetchFilterParams, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  if (!siteId) return Promise.reject();

  const currentPage = getCurrentPage({ offset: params.offset, pageSize: params.limit });

  return axios.get(`/trucks?SiteId=${siteId}&${ObjectToQueryParam(params)}`).then((res) => ({ currentPage, ...res.data }));
});

export const fetchAllTrucks = createAsyncThunk("allTrucks/fetch", async (_, { getState }) => {
  const siteId = getSelectedSiteId(getState);
  if (!siteId) return Promise.reject();

  return axios.get(`trucks/light?siteId=${siteId}`).then((res) => res.data);
});

const trucksSlice = createSlice({
  name: "trucks",
  initialState,
  reducers: {
    setSelectedTruck(state, action) {
      state.selectedTruckId = action.payload;
    },
  },
  extraReducers: {
    [fetchTrucks.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.recordsTotal = action.payload.recordsTotal;
      state.currentPage = action.payload.currentPage;
    },
    [fetchTrucks.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchTrucks.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [fetchAllTrucks.fulfilled.type]: (state, action) => {
      state.loadingAllTrucks = false;
      state.allTrucks = action.payload;
    },
    [fetchAllTrucks.pending.type]: (state, action) => {
      state.loadingAllTrucks = true;
    },
    [fetchAllTrucks.rejected.type]: (state, action) => {
      state.loadingAllTrucks = false;
    },
  },
});
export const { setSelectedTruck } = trucksSlice.actions;
export type TrucksSlice = typeof initialState;
export default trucksSlice.reducer;
